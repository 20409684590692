import { useState, useCallback, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import Header from "./components/Header";
import AddInput from "./components/AddInput";
import TodoItem from "./components/TodoItem";
import ViewModal from "./components/Modal/ViewModal";
import EditModal from "./components/Modal/EditModal";

function App() {
  const [isInputShow, setInputShow] = useState(false),
    [todoList, setTodoList] = useState([]);

  useEffect(() => {
    const todoListInLocalStorage =
      JSON.parse(localStorage.getItem("TodoList")) || [];
    setTodoList(todoListInLocalStorage);
  }, []);

  useEffect(() => {
    localStorage.setItem("TodoList", JSON.stringify(todoList));
  }, [todoList]);

  const addItem = useCallback((value) => {
    const dataItem = {
      id: new Date().getTime(),
      content: value,
      isCompleted: false,
    };

    setTodoList((list) => [...list, dataItem]);
    setInputShow(false);
  }, []);

  const [viewModalShow, setViewModalShow] = useState(true);

  const [currentId, setId] = useState(null);
  const [currentItem, setItem] = useState();

  useEffect(() => {
    getItem();
  }, [currentId]);

  const getItem = () => {
    const item = todoList.find((i) => i.id === currentId);
    setItem(item);
  };

  return (
    <>
      <Header openInput={() => setInputShow((isShow) => !isShow)} />

      <AddInput isInputShow={isInputShow} addItem={addItem} />

      <ListGroup as="ul">
        {todoList.map((item, index) => {
          return (
            <TodoItem
              data={item}
              key={index}
              setViewModalShow={setViewModalShow}
              setId={setId}
            />
          );
        })}
      </ListGroup>

      <ViewModal
        data={currentItem}
        show={viewModalShow}
        onHide={() => setViewModalShow(false)}
      />

      <EditModal data={currentItem} />
    </>
  );
}

export default App;
